/* General Styles */
body, #root {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  background: #e9ecef;
  height: 100%; /* Asegúrate de que el cuerpo tenga altura completa */
  /*overflow: hidden;*/
}

a {
  color: #f0bedc;
  text-decoration: none;
}

a:hover {
  color: #f0bedc;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Jost", sans-serif;
}

/* App Layout */

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
  /*padding-top: 70px;*/ /* Esto debe ser igual a la altura del header */


/* Preloader */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #c5bfc3;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #c5bfc3;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Main Content Area */
.app-main {
  flex: 1;
  /*padding: 10px;*/
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

