.cart-customModal {
    /* Estilos para el modal */
    background-color: rgba(255, 255, 255, 0);
    padding: 10px;
    border-radius: 10px;
    max-width: 99vh;
    max-height: 99vh;
    /*margin: auto;*/
    position: relative;
    overflow-y: auto;
    z-index: 1001; /* Asegura que el modal esté por encima de otros elementos */
}

.cart-customModalContent {
    /* Estilos para el modal */
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 10px;
    max-width: 95vh;
    max-height: 95vh;
    /*margin: auto;*/
    position: relative;
    overflow-y: auto;
    z-index: 1002; /* Asegura que el modal esté por encima de otros elementos */
}

.cart-customModalOverlay {
    /* Estilos para la superposición del modal */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fondo oscuro, debe estar justo debajo del contenido del modal */
}

.cart-close-button {
    z-index: 1003;
    background-color: rgb(255, 255, 255); /* Fondo blanco */
    border-radius: 50%; /* Forma completamente circular */
    border: none; /* Sin borde */
    position: absolute; /* Posición absoluta dentro del modal */
    top: 5px; /* Ajusta la posición superior */
    right: 5px; /* Ajusta la posición derecha */
    cursor: pointer; /* Cambia el cursor a una mano al pasar por encima */
    padding: 10px; /* Añade un pequeño relleno para un mejor tamaño y apariencia */
    width: 20px; /* Ajusta el ancho para mantener la forma circular */
    height: 20px; /* Ajusta la altura para mantener la forma circular */
    display: flex; /* Centra el contenido dentro del botón */
    justify-content: center;
    align-items: center;
}

.cart-close-button img {
    width: 24px; /* Ajusta según tus necesidades */
    height: 24px;
  }

.cart-modal-content {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
}

.cart-table-container {
    width: 100%;
}

#tblcarrito {
    width: 100%;
    border-collapse: collapse;
}

#tblcarrito th, #tblcarrito td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.cart-product-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.cart-totals {
    margin-top: 20px;
    font-weight: bold;
}

.cart-export-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-export-button {
    margin: 5px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cart-delete-button {
    cursor: pointer;
    transition: transform 0.2s ease;
    max-width: 20px; /* Tamaño máximo de 50px */
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-delete-button img {
    width: 100%; /* Ajustar la imagen al tamaño del botón */
    height: auto;
}

.cart-delete-button:hover {
    transform: scale(1.2);
}

.cart-delete-button:active {
    transform: scale(0.9);
}
  
